<script setup lang="ts">
// Dependencies - Vendor
import { computed, onMounted, ref } from 'vue';

// Dependencies - Framework
import { reloadWorkbench } from '@/globals';
import { useMainStore } from '@/stores/mainStore';
import { version } from '@/config.json';

// Dependencies - Component
import SimpleButton from '@/components/SimpleButton.vue';

// Attributes, Emitted Events, Options, Properties & Slots
const { error } = defineProps<{ error?: { message: string } }>();

// Global State
const mainStore = useMainStore();

// Constants
const MODULE_LABEL_MAP: Record<string, string> = {
    AboutPanel: 'About Module',
    AccountPanel: 'Account Module',
    ChatPanel: 'Chat Module',
    DocumentationPanel: 'Documentation Module'
};

// Reactive Variables & Watchers
const internetConnectionIsOnline = computed<boolean | undefined>((): boolean | undefined => mainStore.internetConnectionIsOnline);
const message = ref<string | undefined>(undefined);

// Lifecycle Event Handlers
onMounted((): void => {
    message.value = buildMessage();
});

// Utilities - Build Message
const buildMessage = (): string => {
    if (!error || !error.message) {
        return `Failed to load module for unspecified reason.<br/><br/>Click the Reload button to restart the Workbench.`;
    }

    let errorMessage = `${error.message}${error.message.endsWith('.') ? '' : '.'}`;
    errorMessage = errorMessage.replace(/(https?:\/\/[^\s]+)/gu, (url): string => `'<span class="break-all">${url}</span>'`);

    const matchMap = errorMessage.match(/\/assets\/([A-Za-z0-9]+)[-.]/u);
    const moduleName = matchMap ? matchMap[1] : undefined;
    const moduleLabel = moduleName ? MODULE_LABEL_MAP[moduleName] : 'module';

    if (mainStore.workbenchLastDeployedVersion && mainStore.workbenchLastDeployedVersion !== version && errorMessage.startsWith('Failed to fetch dynamically imported module:')) {
        return `You are using a old version (${version}) of the Workbench.<br/><br/>The '${moduleLabel}' was not previously downloaded and is no longer available.<br/><br/>Click the Reload button to update the Workbench to the latest version (${mainStore.workbenchLastDeployedVersion}).`;
    }

    if (!internetConnectionIsOnline.value) {
        return `Your internet connection appears to be offline.<br/><br/>The '${moduleLabel}' can not be downloaded at this time.<br/><br/>Ensure you have an active internet connection and click the Reload button to update the Workbench and download the module.`;
    }

    return `${errorMessage}<br/><br/>Click the Reload button to restart the Workbench.`;
};
</script>

<template>
    <div class="flex h-full flex-col items-center justify-center overflow-y-scroll overscroll-y-none bg-surface-light p-4 text-caption-light dark:bg-surface-dark dark:text-caption-dark">
        <div class="flex max-w-80 flex-col items-center gap-y-4">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="text-sm" v-html="message" />

            <SimpleButton class="flex-none" kind="alert" @click.stop="reloadWorkbench">Reload</SimpleButton>
        </div>
    </div>
</template>
