<script lang="ts" setup>
// Dependencies - Vendor
import { computed, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// Dependencies - Framework
import { actionIcons, optionIcons } from '@/assets/icons';
import { type BenchtopConfig, type OptionConfig, type WorkbenchConfig, workbenchConfigData } from '@/views/WorkbenchConfig';
import { type DrawerStateId, type DrawerWidthId, useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import OptionDrawerSplitButton from '@/views/optionDrawer/OptionDrawerSplitButton.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Global State
const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();

// Constants
const PATH_SEGMENTS: string[] = route.path.split('/');
const WORKFLOW_CONFIG: WorkbenchConfig = workbenchConfigData;

// Reactive Variables & Watchers
const benchtopConfig = shallowRef<BenchtopConfig>(WORKFLOW_CONFIG.benchtops[2]);
const labelsAreVisible = computed<boolean>((): boolean => optionDrawerStateId.value === 'floating' || (optionDrawerStateId.value === 'expanded' && optionDrawerWidthId.value === 'wide'));
const optionDrawerPanelId = computed<string>((): string => mainStore.optionDrawerPanelId);
const optionDrawerStateId = computed<DrawerStateId>((): DrawerStateId => mainStore.optionDrawerStateId);
const optionDrawerMenuWidth = computed<number>((): number => mainStore.optionDrawerMenuWidth);
const optionDrawerWidthId = computed<DrawerWidthId>((): DrawerWidthId => mainStore.optionDrawerWidthId);

// Initialisation
mainStore.optionDrawerPanelId = PATH_SEGMENTS.length > 2 ? PATH_SEGMENTS[2] : 'home';

// UI Event Handlers - Navigate To Benchtop
const handleNavigateToBenchtop = async (index: number): Promise<void> => {
    benchtopConfig.value = WORKFLOW_CONFIG.benchtops[index];
    await handleNavigateToOption(WORKFLOW_CONFIG.benchtops[index].options[0], 'home');
};

// UI Event Handlers - Navigate To Option
const handleNavigateToOption = async (optionConfig: OptionConfig, optionId: string): Promise<void> => {
    mainStore.optionDrawerPanelId = optionId;
    if (optionDrawerStateId.value === 'floating') {
        mainStore.collapseOptionDrawer();
    }
    await router.push({ path: optionConfig.to });
};

// UI Event Handlers - Toggle Option Drawer
const handleToggleOptionDrawer = (): void => mainStore.toggleOptionDrawer();
</script>

<template>
    <div
        class="flex max-w-[calc(100%-16px)] flex-col border"
        :class="
            optionDrawerStateId === 'collapsed'
                ? 'absolute h-auto border-transparent dark:border-transparent'
                : `h-full bg-surface-light dark:bg-surface-dark ${optionDrawerStateId === 'floating' ? 'absolute border-separator-light dark:border-separator-dark' : 'border-y-transparent border-l-transparent border-r-separator-light dark:border-y-transparent dark:border-l-transparent dark:border-r-separator-dark'}`
        "
        :style="{ width: `${optionDrawerMenuWidth || 72}px` }"
    >
        <!-- Toggle Button -->
        <div class="flex h-[calc(56px-1px)] flex-none flex-col justify-center px-4">
            <SimpleButton class="flex-none gap-x-2" kind="option" @click="handleToggleOptionDrawer">
                <SVGIcon class="h-9 w-9" :svg="actionIcons.toggleSVG" />

                <div v-if="labelsAreVisible" class="truncate pr-2 text-left">{{ $translate('workbench.label') }}</div>
            </SimpleButton>
        </div>

        <!-- Divider -->
        <HorizontalDivider v-if="optionDrawerStateId !== 'collapsed'" class="mx-4" />

        <!-- Options Panel -->
        <div v-if="optionDrawerStateId !== 'collapsed'" class="flex flex-1 flex-col overflow-y-scroll overscroll-y-none px-4" :class="optionDrawerStateId === 'floating' ? '' : ''">
            <!-- Benchtop Options -->
            <div class="flex flex-1 flex-col pb-3">
                <template v-for="(optionConfig, optionIndex) in benchtopConfig.options" :key="optionConfig.id">
                    <HorizontalDivider v-if="optionConfig.isNewGroup" class="mt-2" />

                    <OptionDrawerSplitButton
                        v-if="optionIndex === 0"
                        :benchtop-config="benchtopConfig"
                        :labels-are-visible="labelsAreVisible"
                        :option-config="optionConfig"
                        @navigate-to-benchtop="handleNavigateToBenchtop"
                        @navigate-to-option="handleNavigateToOption"
                    />

                    <SimpleButton
                        v-else
                        class="relative mt-2 flex-none gap-x-2"
                        :data-selected="optionConfig.id === optionDrawerPanelId"
                        kind="option"
                        @click="() => handleNavigateToOption(optionConfig, optionConfig.id)"
                    >
                        <SVGIcon class="h-9 w-9" :svg="optionIcons[`${optionConfig.id}SVG`]" />

                        <div v-if="labelsAreVisible" class="truncate pr-2 text-left">{{ $translate(`${optionConfig.id}.label`) }}</div>
                    </SimpleButton>
                </template>
            </div>
        </div>
    </div>
</template>
