<script lang="ts" setup>
// Dependencies - Vendor
import { computed, onErrorCaptured, onMounted, ref, watch } from 'vue';
import { RouterView } from 'vue-router';
import { useResizeObserver } from '@vueuse/core';

// Dependencies - Framework
import { type DrawerStateId, useMainStore } from '@/stores/mainStore';
import { handleError } from '@/globals';

// Dependencies - Component
import AlertBox, { type AlertConfig } from '@/components/AlertBox.vue';
import DefaultMask from '@/components/DefaultMask.vue';
import LoadingMask from '@/components/LoadingMask.vue';
import OptionDrawer from '@/views/optionDrawer/OptionDrawer.vue';
import ToolDrawer from '@/views/toolDrawer/ToolDrawer.vue';
import ToolMenu from '@/views/toolMenu/ToolMenu.vue';
import HorizontalTransition from '@/components/HorizontalTransition.vue';

// Global State
const mainStore = useMainStore();

// Reactive Elements
const toolMenuRef = ref<HTMLDivElement | null>(null);

// Reactive Variables & Watchers
const alertConfigs = computed<AlertConfig[]>((): AlertConfig[] => mainStore.alertConfigs);
const navigationDepth = computed<number>((): number => mainStore.navigationDepth); // TODO: Reimplement back button for PWA version.
const optionDrawerStateId = computed<DrawerStateId>((): DrawerStateId => mainStore.optionDrawerStateId);
const optionDrawerWidth = computed<number>((): number => mainStore.optionDrawerWidth);
const toolDrawerStateId = computed<DrawerStateId>((): DrawerStateId => mainStore.toolDrawerStateId);
const toolDrawerWidth = computed<number>((): number => mainStore.toolDrawerWidth);
const toolMenuDropdownIsOpen = computed<boolean>((): boolean => mainStore.toolMenuDropdownIsOpen);
const workbenchMaskIsActive = computed<boolean>((): boolean => mainStore.workbenchMaskIsActive);
const workbenchModuleIsLoading = computed<boolean>((): boolean => mainStore.workbenchModuleIsLoading);

// Lifecycle Event Handlers
onErrorCaptured((error): boolean => handleError(error, { locator: 'Workbench.1' }));
onMounted((): void => {
    useResizeObserver(toolMenuRef.value, (entries): void => {
        mainStore.toolMenuWidth = entries[0].contentRect.width;
    });
});

// UI Event Handlers - Collapse Drawer
const handleCollapseDrawer = (): void => {
    if (mainStore.optionDrawerStateId === 'floating') {
        mainStore.collapseOptionDrawer();
    }
    if (mainStore.toolDrawerStateId === 'floating') {
        mainStore.collapseToolComponents();
    }
};
</script>

<template>
    <!-- Layers -->
    <!-- z-10: Workbench Component -->
    <!-- z-20: View Header (see '@/components/ViewHeader.vue') -->
    <!-- z-30: Option Drawer & Tool Drawer (when NOT floating) -->
    <!-- z-40: Drawer Floating Mask -->
    <!-- z-50: Tool Menu, Option Drawer (when floating) & Tool Drawer (when floating) -->
    <!-- z-60: Workbench Mask & Dialog Panel -->
    <!-- z-70: Alert Box -->

    <!-- Workbench -->
    <div class="h-full">
        <!-- Tool Menu - Positioned in top right corner. -->
        <ToolMenu ref="toolMenuRef" class="fixed right-0 top-0 z-50 bg-backdrop-light dark:bg-backdrop-dark" />

        <!-- Body -->
        <div class="flex h-full">
            <!-- Option Drawer - Positioned on left side. -->
            <OptionDrawer class="transition-width flex-none duration-300 ease-in-out" :class="optionDrawerStateId === 'floating' ? 'z-50' : 'z-30'" />

            <!-- Workbench Component - Position in center between drawers, when they are not floating, and under tool menu. -->
            <!-- <div class="relative z-10 h-full" :style="{ width: `calc(100% - ${optionDrawerWidth}px - ${toolDrawerStateId === 'expanded' ? toolDrawerWidth : 0}px)` }"> -->
            <div class="relative z-10 h-full" :style="{ width: `calc(100% - ${optionDrawerWidth}px` }">
                <RouterView v-slot="{ Component }">
                    <Transition appear name="fade">
                        <LoadingMask v-if="workbenchModuleIsLoading" />
                        <component :is="Component" v-else-if="Component" />
                    </Transition>
                </RouterView>
            </div>

            <!-- Tool Drawer - Position on right side below tool menu. -->
            <HorizontalTransition>
                <!-- <ToolDrawer v-if="toolDrawerStateId !== 'collapsed'" class="mt-14 flex-none" :class="toolDrawerStateId === 'floating' ? 'z-50' : 'z-30'" :style="{ width: `${toolDrawerWidth}px` }" /> -->
                <ToolDrawer v-if="toolDrawerStateId !== 'collapsed'" class="transition-width mt-14 flex-none duration-300 ease-in-out" :class="toolDrawerStateId === 'floating' ? 'z-50' : 'z-30'" />
            </HorizontalTransition>
        </div>
    </div>

    <!-- Drawer Mask - Only displayed when the option drawer is floating or the tool drawer is open. -->
    <DefaultMask v-if="optionDrawerStateId === 'floating' || toolMenuDropdownIsOpen" class="z-40" is-opaque @click="handleCollapseDrawer" />

    <!-- Workbench Mask - Sits above all elements except the alert box scroller. -->
    <DefaultMask v-if="workbenchMaskIsActive" key="default-mask-2" class="z-60" is-opaque />

    <!-- Alerts - See '#alertBoxScroller' in 'index.html' with 'z-index: 70'. -->
    <Teleport to="#alertBoxScroller">
        <template v-for="alertConfig in alertConfigs" :key="alertConfig.id">
            <AlertBox :config="alertConfig" />
        </template>
    </Teleport>
</template>
