<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';

// Dependencies - Framework
import { actionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Attributes, Emitted Events, Options, Properties & Slots
const emit = defineEmits<(event: 'action', id: string) => void>();

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const toolMenuDataActionsEnabled = computed<boolean>((): boolean => mainStore.toolMenuDataActionsEnabled);

// UI Event Handlers - Action
const handleAddAction = (): void => emit('action', 'add');
const handleCancelAction = (): void => emit('action', 'cancel');
const handleSaveAction = (): void => emit('action', 'save');
</script>

<template>
    <!-- Add Action -->
    <SimpleButton :disabled="!toolMenuDataActionsEnabled" kind="option" @click="handleAddAction">
        <SVGIcon class="h-9 w-9" :svg="actionIcons.addSVG" />
    </SimpleButton>

    <!-- Save Action -->
    <SimpleButton :disabled="!toolMenuDataActionsEnabled" kind="option" @click="handleSaveAction">
        <SVGIcon class="h-9 w-9" :svg="actionIcons.saveSVG" />
    </SimpleButton>

    <!-- Cancel Action -->
    <SimpleButton :disabled="!toolMenuDataActionsEnabled" kind="option" @click="handleCancelAction">
        <SVGIcon class="h-9 w-9" :svg="actionIcons.cancelSVG" />
    </SimpleButton>
</template>
