<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';
import { useFullscreen } from '@vueuse/core';

// Dependencies - Framework
import { type Appearance, useMainStore } from '@/stores/mainStore';
import { actionIcons } from '@/assets/icons';

// Dependencies - Component
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const appearance = computed<Appearance>((): Appearance => mainStore.appearance);
const { isFullscreen, isSupported: isFullscreenSupported, toggle: toggleFullscreen } = useFullscreen();

// UI Event Handlers - Toggle Appearance
const handleToggleAppearance = (): void => {
    switch (appearance.value.id) {
        case 'dark':
            return mainStore.applyAppearanceUsingId('system');
        case 'light':
            return mainStore.applyAppearanceUsingId('dark');
        default:
            return mainStore.applyAppearanceUsingId('light');
    }
};
</script>

<template>
    <!-- Toggle Fullscreen -->
    <SimpleButton :disabled="!isFullscreenSupported" kind="option" @click="toggleFullscreen">
        <div class="flex items-center">
            <SVGIcon class="h-9 w-9" :svg="isFullscreen ? actionIcons.exitFullscreenSVG : actionIcons.enterFullscreenSVG" />
        </div>
    </SimpleButton>

    <!-- Toggle Appearance -->
    <SimpleButton kind="option" @click="handleToggleAppearance">
        <div class="flex items-center">
            <SVGIcon class="h-9 w-9" :svg="actionIcons[`appear${appearance.nextId.charAt(0).toUpperCase()}${appearance.nextId.slice(1)}SVG`]" />
        </div>
    </SimpleButton>
</template>
