// Dependencies - Vendor
import { createApp } from 'vue';
import { createPinia } from 'pinia';

// Dependencies - Framework
import '@/assets/main.css';
import { handleError, initAppMonitor } from '@/globals';
import { initTranslations } from '@/locales';
import router from '@/router';

// Dependencies - Component
import App from '@/App.vue';

// Utilities - Load Application
const loadApp = async (): Promise<void> => {
    try {
        // Create the main Vue instance using the root component.
        const application = createApp(App);
        application.config.errorHandler = (error: unknown, vm: unknown, info: string): boolean => handleError(error, { info, locator: 'main.loadApp.errorHandler.1' }); // Report unhandled error.

        // Register plugins.
        application.use(createPinia());
        application.use(router);

        // Initialize translations before proceeding.
        await initTranslations(application);

        // Asynchronously load and initialize the application monitor so we don't block the application mount.
        // The application monitor is also responsible for asynchronously loading the session manager.
        // We communicate with the application monitor via helper functions in 'src/global.ts'.
        import('@/appMonitor')
            .then((module): void => {
                try {
                    initAppMonitor(module).catch((error): boolean => handleError(error, { locator: 'main.loadApp.5' })); // Report application load error.
                } catch (error) {
                    handleError(error, { locator: 'main.loadApp.4' }); // Report application load error.
                }
            })
            .catch((error): boolean => handleError(error, { locator: 'main.loadApp.3' })); // Report application load error.

        // Mount the application to the DOM. This will begin before the application monitor and session manager have completed loading.
        application.mount('#app');
    } catch (error) {
        handleError(error, { locator: 'main.loadApp.2' }); // Report application load error.
    }
};

// Load Application
loadApp().catch((error): void => {
    handleError(error, { locator: 'main.loadApp.1' }); // Report application load error.
});
