<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';

// Dependencies - Framework
import { type BenchtopConfig, type OptionConfig, type WorkbenchConfig, workbenchConfigData } from '@/views/WorkbenchConfig';
import { optionIcons } from '@/assets/icons';
import { useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import DropdownButton from '@/components/DropdownButton.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Attributes, Emitted Events, Options, Properties & Slots
const { benchtopConfig, labelsAreVisible, optionConfig } = defineProps<{ benchtopConfig: BenchtopConfig; labelsAreVisible: boolean; optionConfig: OptionConfig }>();
const emit = defineEmits<{ (event: 'navigateToBenchtop', index: number): void; (event: 'navigateToOption', config: OptionConfig, id: string): void }>();

// Global State
const mainStore = useMainStore();

//Constants
const WORKFLOW_CONFIG: WorkbenchConfig = workbenchConfigData;

// Reactive Variables & Watchers
const optionDrawerPanelId = computed((): string => mainStore.optionDrawerPanelId);

// UI Event Handlers - Navigate to Benchtop
const navigateToBenchtop = (index: number): void => emit('navigateToBenchtop', index);

// UI Event Handlers - Navigate to Option
const handleNavigateToOption = (): void => emit('navigateToOption', optionConfig, 'home');
</script>

<template>
    <div class="mt-2 flex flex-none gap-x-1 rounded bg-backdrop-light dark:bg-backdrop-dark">
        <SimpleButton class="w-full flex-1" :class="labelsAreVisible ? 'rounded-r-none' : 'rounded-r'" :data-selected="optionConfig.id === optionDrawerPanelId" kind="option" @click="handleNavigateToOption">
            <div class="flex w-full items-center gap-x-2">
                <SVGIcon class="h-9 w-9" :svg="optionIcons[`${benchtopConfig.id}SVG`]" />

                <div v-if="labelsAreVisible" class="truncate text-left">{{ $translate(`${benchtopConfig.id}.label`) }}</div>
            </div>
        </SimpleButton>

        <DropdownButton
            v-if="labelsAreVisible"
            class="dp-dropdown-button flex-none rounded-l-none"
            :items="WORKFLOW_CONFIG.benchtops.map((benchtop) => ({ id: benchtop.id, label: $translate(`${benchtop.id}.label`) }))"
            @select="navigateToBenchtop"
        />
    </div>
</template>
