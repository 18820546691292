// Dependencies - Vendor
import type { App } from 'vue';

// Dependencies - Framework
import englishTranslations from '@/locales/en.json';

// Interfaces/Types - Component Custom Properties
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $translate(id: string, params?: Record<string, number | string>): string;
    }
}

// Constants
const SUPPORTED_LANGUAGE_IDS: string[] = ['en', 'es'];

// Variables
let preferredLanguage = 'en';
let translations: Record<string, string>;

// Helpers - Get Preferred Language
export const getPreferredLanguage = (): string => preferredLanguage;

// Helpers - Initialise Translations
export const initTranslations = async (app: App<Element>): Promise<void> => {
    preferredLanguage = establishPreferredLanguageId();
    translations = await loadTranslations(preferredLanguage);
    app.config.globalProperties.$translate = translate;
};

// Helpers - Translate
export const translate = (id: string, params?: Record<string, number | string>): string => {
    const text = translations[id];
    if (!text) {
        return `??:${id}`;
    }
    return text.replace(/\{(\w+)\}|\{('.*?')\}/gu, (match, paramId, fixedString): string => {
        if (fixedString) {
            return fixedString.slice(1, -1); // Remove surrounding quotes and return fixed string.
        }
        return params && paramId in params ? String(params[paramId]) : paramId; // Return parameter value or parameter identifier if not found.
    });
};

// Helpers - Load Translations
export const loadTranslations = async (id: string): Promise<Record<string, string>> => {
    if (id === 'es') {
        return (await import('@/locales/es.json')).default;
    }
    return englishTranslations;
};

// Utilities - Establish Preferred Language Identifier
const establishPreferredLanguageId = (): string => {
    // Check for exact language match.
    for (const languageId of window.navigator.languages) {
        const transformedLanguageId = languageId.toLowerCase();
        if (SUPPORTED_LANGUAGE_IDS.includes(transformedLanguageId)) {
            return transformedLanguageId;
        }
    }
    // Check for neutral language match.
    for (const languageId of window.navigator.languages) {
        const transformedLanguageId = languageId.split('-')[0].toLowerCase();
        if (SUPPORTED_LANGUAGE_IDS.includes(transformedLanguageId)) {
            return transformedLanguageId;
        }
    }
    return 'en'; // Default to english.
};
