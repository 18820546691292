// Constants
export const API_ENDPOINTS = {
    OPERATIONS: {
        CONFIG: 'https://operations.datapositioning.app/config',
        CONNECTORS: 'https://operations.datapositioning.app/connectors',
        LOCATION: 'https://operations.datapositioning.app/location',
        MONITORS: 'https://operations.datapositioning.app/monitors',
        PACKAGES: 'https://operations.datapositioning.app/packages',
        SERVICES: {
            WEBSOCKET: 'wss://operations.datapositioning.app/services/websocket'
        },
        TOKEN: 'https://operations.datapositioning.app/token'
    },
    MESSENGER: {
        CONFIG: 'https://messenger.datapositioning.app/config',
        ROOMS: 'wss://messenger.datapositioning.app/rooms'
    },
    RESOURCES: {
        CONFIG: 'https://resources.datapositioning.app/config'
    },
    WORKBENCH: {
        INSTANCE: '/instance'
    }
};

// Constants
export const STALE_TIME = 300000; // Calculated as 5 mins * 60 secs * 1000 ms;
