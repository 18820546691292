/* eslint-disable max-lines */
// Dependencies - Vendor
import { ref, shallowRef } from 'vue';
import { defineStore } from 'pinia';
import { nanoid } from 'nanoid';

// Dependencies - Framework
import type { ConnectionConfig } from '@datapos/datapos-share-core';
import { LOCAL_STORAGE } from '@/globals';
import type { ToolPanelId } from '@/views/toolMenu/ToolTabs.vue';

// Dependencies - Component
import type { AlertConfig } from '@/components/AlertBox.vue';

// Interfaces/Types - Appearance
export interface Appearance {
    id: AppearanceId;
    isDark?: boolean;
    nextId: AppearanceId;
}
type AppearanceId = 'dark' | 'light' | 'system';

// Interfaces/Types - Authorisation Flow  UI (Input Error, State)
export interface AuthFlowUIInputError {
    error: {
        code: string;
        message: string;
    };
    max_length?: number;
    min_length?: number;
    name?: string;
    required?: boolean;
    type?: string;
    value?: string;
}
export type AuthFlowUIState = 'chooseMethod' | 'enterPasscode' | 'enterPassword' | 'enterHandle' | 'manageAccess' | 'manageAccount' | 'reauthorise' | 'signIn' | 'signUp';

// Interfaces/Types - Drawer
export type DrawerStateId = 'collapsed' | 'expanded' | 'floating';
export type DrawerWidthId = 'narrow' | 'wide';

// Interfaces/Types - User
export interface User {
    id: string;
    emailAddress?: string;
    key?: string;
}

// Constants
const DEFAULT_DELAY = 200;
const DRAWER_MAXIMUM_WIDTH = 640;
const DRAWER_MEDIAN_WIDTH = 320;
const DRAWER_MINIMUM_WIDTH = 74;
const WINDOW_BREAK_POINT = 1280;

// Utilities
const getDrawerStateId = (localStorageId: string): DrawerStateId => (window.localStorage.getItem(localStorageId) === 'expanded' ? 'expanded' : 'collapsed');
const getDrawerWidthId = (localStorageId: string): DrawerWidthId => (window.localStorage.getItem(localStorageId) === 'wide' ? 'wide' : 'narrow');

// Main Store - Compose & Expose
// eslint-disable-next-line max-lines-per-function, max-statements, @typescript-eslint/explicit-function-return-type
export const useMainStore = defineStore('main', () => {
    // ALERT: Properties & Variables
    const alertConfigs = ref<AlertConfig[]>([]); // This cannot be shallow as individual timers are added and removed as required.

    // ALERT: Methods - Show Alert
    const showAlert = (alertConfig: AlertConfig): void => {
        alertConfigs.value.push({ ...alertConfig, id: nanoid() });
    };

    // APPEARANCE: Utilities - Build Initial Appearance
    const buildInitialAppearance = (id: AppearanceId): Appearance => {
        switch (id) {
            case 'dark':
                return { id, isDark: true, nextId: 'system' };
            case 'light':
                return { id, isDark: false, nextId: 'dark' };
            default: {
                const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
                return { id, isDark, nextId: 'light' };
            }
        }
    };

    // APPEARANCE: Properties & Variables
    const appearance = shallowRef<Appearance>(buildInitialAppearance((window.localStorage.getItem(LOCAL_STORAGE.APPEARANCE.ID) || 'system') as AppearanceId));

    // APPEARANCE: Methods - Apply Appearance Using Id
    const applyAppearanceUsingId = (id: AppearanceId): void => {
        switch (id) {
            case 'dark':
                window.document.documentElement.classList.add('dark');
                appearance.value = { id, isDark: true, nextId: 'system' };
                break;
            case 'light':
                window.document.documentElement.classList.remove('dark');
                appearance.value = { id, isDark: false, nextId: 'dark' };
                break;
            default: {
                const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
                applyAppearanceUsingMode(isDark);
                appearance.value = { id, isDark, nextId: 'light' };
                break;
            }
        }
        localStorage.setItem(LOCAL_STORAGE.APPEARANCE.ID, id);
    };

    // APPEARANCE: Methods - Apply Appearance Using Mode
    const applyAppearanceUsingMode = (isDark: boolean): void => {
        if (isDark) {
            window.document.documentElement.classList.add('dark');
            return;
        }
        window.document.documentElement.classList.remove('dark');
    };

    // APPEARANCE: UI Event Handlers - Watch For System Appearance Change
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event): void => {
        if (appearance.value.id !== 'system') {
            return;
        }
        applyAppearanceUsingMode(event.matches);
    });

    // AUTHORISATION FLOW UI: Properties & Variables
    const authFlowUIInputErrors = shallowRef<AuthFlowUIInputError[]>([]);
    const authFlowUIState = ref<AuthFlowUIState | undefined>(undefined);

    // COMPONENT: Properties & Variables
    const connectionConfigs = shallowRef<ConnectionConfig[]>([]);

    // INTERNET: Properties & Variables
    const internetConnectionIsOnline = ref<boolean | undefined>(undefined);

    // NAVIGATION: Properties & Variables
    const navigationDepth = ref<number>(0);

    // OPTION DRAWER: Properties & Variables
    const optionDrawerPanelId = ref<string>('');
    const optionDrawerStateId = ref<DrawerStateId>('expanded');
    const initialOptionDrawerWidthId = getDrawerWidthId(LOCAL_STORAGE.OPTION_DRAWER.WIDTH_ID);
    const initialOptionDrawerWidth = initialOptionDrawerWidthId === 'narrow' ? DRAWER_MINIMUM_WIDTH : DRAWER_MEDIAN_WIDTH;
    const optionDrawerWidthId = ref<DrawerWidthId>(initialOptionDrawerWidthId);
    const optionDrawerWidth = ref<number>(initialOptionDrawerWidth);
    const optionDrawerMenuWidth = ref<number>(initialOptionDrawerWidth);

    // OPTION DRAWER: Methods - Collapse Option Drawer
    const collapseOptionDrawer = (): void => {
        optionDrawerWidth.value = 0;
        optionDrawerMenuWidth.value = DRAWER_MINIMUM_WIDTH;
        optionDrawerStateId.value = 'collapsed';
    };

    // OPTION DRAWER: Methods - Float Option Drawer
    const floatOptionDrawer = (): void => {
        optionDrawerWidth.value = DRAWER_MEDIAN_WIDTH;
        optionDrawerMenuWidth.value = DRAWER_MEDIAN_WIDTH;
        optionDrawerStateId.value = 'floating';
    };

    // OPTION DRAWER: Methods - Toggle Option Drawer
    const toggleOptionDrawer = (): void => {
        if (windowWidth.value >= WINDOW_BREAK_POINT) {
            optionDrawerStateId.value = 'expanded';
            optionDrawerWidthId.value = optionDrawerWidthId.value === 'narrow' ? 'wide' : 'narrow';
            optionDrawerWidth.value = optionDrawerWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : DRAWER_MEDIAN_WIDTH;
            optionDrawerMenuWidth.value = optionDrawerWidth.value;
            window.localStorage.setItem(LOCAL_STORAGE.OPTION_DRAWER.WIDTH_ID, optionDrawerWidthId.value);
            return;
        }
        if (optionDrawerStateId.value === 'collapsed') {
            floatOptionDrawer();
            return;
        }
        collapseOptionDrawer();
    };

    // SESSION: Properties & Variables
    const sessionExpiresIn = ref<number>(0);
    const sessionIsReauthorising = ref<boolean>(false);
    const sessionLifetime = ref<number>(0);
    const sessionUpdatesArePending = ref<boolean>(false);
    const sessionUser = shallowRef<User | false | undefined>(undefined);

    // TITLE: Properties & Variables
    const titleWidth = ref<number>(0);

    // TOOL COMPONENTS: Properties & Variables
    const toolDrawerPanelId = ref<string | undefined>(window.localStorage.getItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID) || undefined);
    const toolDrawerStateId = ref<DrawerStateId>(getDrawerStateId(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID));
    const initialToolDrawerWidthId = getDrawerWidthId(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID);
    const toolDrawerWidthId = ref<DrawerWidthId>(initialToolDrawerWidthId);
    const toolDrawerWidth = ref<number>(initialToolDrawerWidthId === 'narrow' ? DRAWER_MEDIAN_WIDTH : DRAWER_MAXIMUM_WIDTH);
    const toolMenuDataActionsEnabled = ref<boolean>(false);
    const toolMenuDropdownIsOpen = ref<boolean>(false);
    const toolMenuWidth = ref<number>(0);

    // TOOL COMPONENTS: Methods - Collapse Tool Components
    const collapseToolComponents = (): void => {
        toolMenuDropdownIsOpen.value = false;
        toolDrawerPanelId.value = undefined;
        toolDrawerStateId.value = 'collapsed';
    };

    // TOOL COMPONENTS: Methods - Enable Tool Components Menu Data Actions
    const enableToolComponentsMenuDataActions = (enabled: boolean): void => {
        navigationDepth.value = 0;
        toolMenuDataActionsEnabled.value = enabled;
        // HideWorkbenchMask(); // TODO
    };

    // TOOL COMPONENTS: Methods - Handle Tool Components Menu Tab Change
    const handleToolComponentsMenuTabChange = (toolPanelId?: ToolPanelId): void => {
        if (windowWidth.value >= WINDOW_BREAK_POINT) {
            if (toolPanelId === undefined || toolDrawerPanelId.value === toolPanelId) {
                window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerPanelId.value = undefined) || '');
                window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, (toolDrawerStateId.value = 'collapsed'));
                return;
            }
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerPanelId.value = toolPanelId));
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, (toolDrawerStateId.value = 'expanded'));
            return;
        }
        if (toolPanelId === undefined) {
            collapseToolComponents();
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerPanelId.value = undefined) || '');
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, toolDrawerStateId.value);
            return;
        }
        toolDrawerStateId.value = 'floating';
        window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerPanelId.value = toolPanelId));
        window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, 'expanded');
    };

    // TOOL COMPONENTS: Methods - Resize Tool Components
    const resizeToolComponents = (): void => {
        toolDrawerWidthId.value = toolDrawerWidthId.value === 'narrow' ? 'wide' : 'narrow';
        toolDrawerWidth.value = toolDrawerWidthId.value === 'narrow' ? DRAWER_MEDIAN_WIDTH : DRAWER_MAXIMUM_WIDTH;
        localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID, toolDrawerWidthId.value);
    };

    // TOOL COMPONENTS: Methods - Toggle Tool Components
    const toggleToolComponents = (): void => {
        if (toolMenuDropdownIsOpen.value || toolDrawerPanelId.value) {
            toolMenuDropdownIsOpen.value = false;
            handleToolComponentsMenuTabChange();
            return;
        }
        toolDrawerStateId.value = 'floating';
        toolMenuDropdownIsOpen.value = true;
    };

    // WINDOW: Properties & Variables
    const windowWidth = ref<number>(0);

    // WINDOW: Methods - Handle Window Width Change
    const handleWindowWidthChange = (width: number): void => {
        if (width >= WINDOW_BREAK_POINT) {
            if (windowWidth.value < WINDOW_BREAK_POINT) {
                // Handle new width  greater than or equal to the breakpoint and prior width less than it.
                optionDrawerStateId.value = 'expanded';
                optionDrawerWidthId.value = getDrawerWidthId(LOCAL_STORAGE.OPTION_DRAWER.WIDTH_ID);
                optionDrawerWidth.value = optionDrawerWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : DRAWER_MEDIAN_WIDTH;
                optionDrawerMenuWidth.value = optionDrawerWidth.value;
                if (toolDrawerStateId.value === 'floating') {
                    toolDrawerStateId.value = 'expanded';
                }
                toolDrawerWidthId.value = getDrawerWidthId(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID);
                toolMenuDropdownIsOpen.value = false;
            }
            windowWidth.value = width;
            return;
        }
        if (windowWidth.value === 0 || windowWidth.value >= WINDOW_BREAK_POINT) {
            // Handle prior width unset or new width less than the breakpoint and prior width greater than or equal to it.
            collapseOptionDrawer();
            toolDrawerStateId.value = getDrawerStateId(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID);
            if (toolDrawerStateId.value === 'expanded') {
                toolDrawerStateId.value = 'floating';
                toolDrawerWidthId.value = 'narrow';
            }
            windowWidth.value = width;
            return;
        }
        // ...
        toolDrawerStateId.value = 'floating';
        toolDrawerWidthId.value = 'narrow';
        localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID, toolDrawerWidthId.value);
        toolDrawerWidth.value = DRAWER_MEDIAN_WIDTH;
        windowWidth.value = width;
    };

    // WORKBENCH: Properties & Variables
    const workbenchIsEUInstance = ref<boolean | undefined>(undefined);
    const workbenchIsLoading = ref<boolean>(false);
    const workbenchLastDeployedVersion = ref<string | undefined>(undefined);
    const workbenchMaskIsActive = ref<boolean>(false);
    let workbenchMaskTimeout: number | undefined = window.setTimeout((): boolean => (workbenchIsLoading.value = true), DEFAULT_DELAY);
    const workbenchModuleIsLoading = ref<boolean>(false);

    // WORKBENCH: Methods - Clear Workbench Mask Timeout
    const clearWorkbenchMaskTimeout = (): void => {
        window.clearTimeout(workbenchMaskTimeout);
        workbenchIsLoading.value = false;
        workbenchMaskTimeout = undefined;
    };

    return {
        // Properties
        alertConfigs,
        appearance,
        authFlowUIInputErrors,
        authFlowUIState,
        connectionConfigs,
        internetConnectionIsOnline,
        navigationDepth,
        optionDrawerPanelId,
        optionDrawerStateId,
        optionDrawerMenuWidth,
        optionDrawerWidth,
        optionDrawerWidthId,
        sessionExpiresIn,
        sessionIsReauthorising,
        sessionLifetime,
        sessionUpdatesArePending,
        sessionUser,
        titleWidth,
        toolDrawerPanelId,
        toolDrawerStateId,
        toolDrawerWidth,
        toolDrawerWidthId,
        toolMenuDataActionsEnabled,
        toolMenuDropdownIsOpen,
        toolMenuWidth,
        windowWidth,
        workbenchIsEUInstance,
        workbenchIsLoading,
        workbenchLastDeployedVersion,
        workbenchMaskIsActive,
        workbenchModuleIsLoading,

        // Actions
        applyAppearanceUsingId,
        clearWorkbenchMaskTimeout,
        collapseOptionDrawer,
        collapseToolComponents,
        enableToolComponentsMenuDataActions,
        handleToolComponentsMenuTabChange,
        handleWindowWidthChange,
        resizeToolComponents,
        showAlert,
        toggleOptionDrawer,
        toggleToolComponents
    };
});
