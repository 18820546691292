<script lang="ts" setup>
// Dependencies - Vendor
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';

// Dependencies - Framework
import { expandCollapseSVG } from '@/assets/icons';

// Dependencies - Component
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Interfaces/Types
interface DropdownItem {
    id: string;
    label: string;
}

// Attributes, Emitted Events, Options, Properties & Slots
defineProps<{ items: { id: string; label: string }[] }>();
const emit = defineEmits<(event: 'select', itemIndex: number, item: DropdownItem) => void>();

// Reactive Elements
const wrapperRef = ref<HTMLDivElement | null>(null);

// Reactive Variables & Watchers
const dropdownMenuIsVisible = ref<boolean>(false);

// UI Event Handlers - Click
const handleClick = (): void => {
    dropdownMenuIsVisible.value = !dropdownMenuIsVisible.value;
};
// UI Event Handlers - Select
const handleSelect = (itemIndex: number, item: { id: string; label: string }): void => {
    emit('select', itemIndex, item);
    dropdownMenuIsVisible.value = false;
};

// UI Event Handlers - On Click Outside Wrapper
onClickOutside(wrapperRef, (): void => {
    dropdownMenuIsVisible.value = false;
});
</script>

<template>
    <div ref="wrapperRef" class="relative">
        <!-- Button -->
        <SimpleButton id="dropdownButton" class="rounded-l-none" kind="dropdown" @click.stop="handleClick">
            <SVGIcon class="h-4 w-4" :svg="expandCollapseSVG" />
        </SimpleButton>

        <!-- Menu -->
        <div v-if="dropdownMenuIsVisible" class="absolute right-0 z-10 mt-1 rounded border border-divider-light bg-surface-light dark:border-divider-dark dark:bg-gray-700 dark:bg-surface-dark">
            <ul class="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownButton">
                <li v-for="(item, itemIndex) in items" :key="item.id">
                    <div class="cursor-pointer whitespace-nowrap px-3 py-2 hover:bg-gray-200" @click="() => handleSelect(itemIndex, item)">
                        {{ item.label }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
