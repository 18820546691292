<script lang="ts" setup>
// Dependencies - Vendor
import { animate } from 'motion';
import { nextTick } from 'vue';

// Attributes, Emitted Events, Options, Properties & Slots
defineSlots<{ default(): unknown }>();

// Constants
const SLIDE_MINIMUM_DURATION = 0.2;
const SLIDE_PIXEL_PER_SECOND = 2000;

// UI Event Handlers - Enter
const handleEnter = async (element: Element, done: () => void): Promise<void> => {
    console.log('handleEnter');
    (element as HTMLElement).style.overflow = 'hidden';
    await nextTick();
    const width = (element as HTMLElement).scrollWidth;
    (element as HTMLElement).style.maxWidth = `${width}px`;
    const duration = SLIDE_MINIMUM_DURATION + width / SLIDE_PIXEL_PER_SECOND;
    await animate(element as HTMLElement, { maxWidth: ['0', `${width}px`], opacity: [0, 1] }, { duration, easing: 'linear' }).finished;
    done();
};

// UI Event Handlers - After Enter
const handleAfterEnter = (element: Element): void => {
    console.log('handleAfterEnter');
    (element as HTMLElement).style.maxWidth = '';
    (element as HTMLElement).style.overflow = '';
};

// UI Event Handlers - Leave
const handleLeave = async (element: Element, done: () => void): Promise<void> => {
    console.log('handleLeave');
    (element as HTMLElement).style.overflow = 'hidden';
    await nextTick();
    const width = (element as HTMLElement).scrollWidth;
    (element as HTMLElement).style.maxWidth = '0';
    const duration = SLIDE_MINIMUM_DURATION + width / SLIDE_PIXEL_PER_SECOND;
    await animate(element as HTMLElement, { maxWidth: [`${width}px`, '0'], opacity: [1, 0] }, { duration, easing: 'linear' }).finished;
    done();
};

// UI Event Handlers - After Leave
const handleAfterLeave = (element: Element): void => {
    console.log('handleAfterLeave');
    (element as HTMLElement).style.maxWidth = '';
    (element as HTMLElement).style.overflow = '';
};
</script>

<template>
    <Transition :css="false" @enter="handleEnter" @after-enter="handleAfterEnter" @leave="handleLeave" @after-leave="handleAfterLeave">
        <slot />
    </Transition>
</template>
