<script lang="ts" setup>
// Dependencies - Vendor
import { type Component, computed, defineAsyncComponent, onErrorCaptured } from 'vue';

// Dependencies - Framework
import { DEFINE_ASYNC_COMPONENT_OPTIONS, handleError } from '@/globals';
import { type DrawerStateId, useMainStore } from '@/stores/mainStore';

// Dependencies - Component
import HorizontalDivider from '@/components/HorizontalDivider.vue';

// Dependencies - Component - Asynchronous
const AboutPanel = defineAsyncComponent({ ...DEFINE_ASYNC_COMPONENT_OPTIONS, loader: (): Promise<Component> => import('./about/AboutPanel.vue') });
const AccountPanel = defineAsyncComponent({ ...DEFINE_ASYNC_COMPONENT_OPTIONS, loader: (): Promise<Component> => import('./account/AccountPanel.vue') });
const ChatPanel = defineAsyncComponent({ ...DEFINE_ASYNC_COMPONENT_OPTIONS, loader: (): Promise<Component> => import('./chat/ChatPanel.vue') });
const DocumentationPanel = defineAsyncComponent({ ...DEFINE_ASYNC_COMPONENT_OPTIONS, loader: (): Promise<Component> => import('./documentation/DocumentationPanel.vue') });

// Global State
const mainStore = useMainStore();

// Reactive Variables & Watchers
const toolDrawerPanelId = computed<string | undefined>((): string | undefined => mainStore.toolDrawerPanelId);
const toolDrawerStateId = computed<DrawerStateId>((): DrawerStateId => mainStore.toolDrawerStateId);
const toolDrawerWidth = computed<number>((): number => mainStore.toolDrawerWidth);

// Lifecycle Event Handlers
onErrorCaptured((error): boolean => {
    if (error.message.startsWith('Failed to fetch dynamically imported module:')) {
        return false;
    }
    return handleError(error, { locator: 'ToolDrawer.1' });
});
</script>

<template>
    <div
        class="h-[calc(100%-56px)] max-w-[calc(100%-16px)] flex-col border-b border-l border-r border-l-separator-light bg-surface-light dark:border-l-separator-dark dark:bg-surface-dark"
        :class="
            toolDrawerStateId === 'floating'
                ? 'absolute right-0 border-b-separator-light border-r-separator-light dark:border-b-separator-dark dark:border-r-separator-dark'
                : 'border-b-transparent border-r-transparent dark:border-b-transparent dark:border-r-transparent'
        "
        :style="{ display: toolDrawerPanelId ? 'flex' : 'none', width: `${toolDrawerWidth}px` }"
    >
        <HorizontalDivider class="mx-4" />

        <div class="relative flex-1">
            <Transition appear name="fade">
                <AboutPanel v-if="toolDrawerPanelId === 'about'" class="absolute bottom-0 left-0 right-0 top-0" />

                <AccountPanel v-else-if="toolDrawerPanelId === 'account'" class="absolute bottom-0 left-0 right-0 top-0" />

                <ChatPanel v-else-if="toolDrawerPanelId === 'chat'" class="absolute bottom-0 left-0 right-0 top-0" />

                <DocumentationPanel v-else-if="toolDrawerPanelId === 'documentation'" class="absolute bottom-0 left-0 right-0 top-0" />
            </Transition>
        </div>
    </div>
</template>
